@tailwind base;
@tailwind components;
@tailwind utilities;

circle.cls-1 {
  fill: #09b;
}
path.cls-2 {
  fill: #fff;
}

h1.zoe-invitation_header {
  font-family: "Courgette", cursive;
}

.invitation_text-concert {
  font-family: "Concert One", cursive;
}

.invitation_text-rampart {
  font-family: "Rampart One", cursive;
}

.zoe-invi_background {
  background-size: cover;
  max-height: 100%;
  overflow: hidden;
  z-index: 900;
}

.starfall {
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100vh;
}

.falling_star {
  display: inline-block;
  color: #59008d;
  opacity: 0;
  margin: 0;
  padding: 0;
  animation: fall 16s linear infinite;
}

@keyframes fall {
  0% {
    opacity: 0;
  }
  3% {
    opacity: 0.9;
  }
  100% {
    transform: translate(0, 97vh);
    opacity: 0;
  }
}
